import { Article, Author } from "../../articleInterfaces";
import clipping from "../resources/jul-27-24.pdf";
import thequad from "./thequad.jpg";
import lights from "./lights.jpg";
import { content } from "./content";

const THIS: Author = {
  name: "Magnus Fulton",
  school: "West Valley High School",
  grade: "graduate"
};

const article: Article = {
  articleType: "column",
  author: [ THIS ],
  date: new Date(2024, 6, 27),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/senior-farewell-the-awe-we-find-in-the-human-glow/article_4f9c45d2-4331-11ef-90e1-bfacf8afb0a0.html",
  title: "Senior farewell: The awe we find in the human glow",
  clipping,
  images: [
    {
      src: thequad,
      caption: "Magnus Fulton, 2024 West Valley graduate, stands March 24, 2024, in The Quad under cherry trees on the University of Washington campus where he’ll study computer science this fall.",
      credit: THIS
    },
    {
      src: lights,
      caption: "Seen from Snow Mountain Ranch on May 10, the aurora glows over a glittering sea of house lights below.",
      credit: THIS
    }
  ],
  content
};

export default article;